<template>
  <svg
    xmlns:xlink="http://www.w3.org/1999/xlink"
    class="va-icon-slower"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- Generator: sketchtool 48.2 (47327) - http://www.bohemiancoding.com/sketch -->
    <title>67046716-A590-445C-AC65-1EEF69089C00</title>
    <defs />
    <g
      id="symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icon-slower"
        fill="#34495E"
      >
        <g>
          <path
            d="M16.82,18.87 L16.028,16.17 L12.014,16.17 L11.24,18.87 L8,18.87 L12.374,6 L15.686,6 L20.06,18.87 L16.82,18.87 Z M14.048,8.934 L14.012,8.934 C14.012,8.934 13.724,10.338 13.49,11.148 L12.752,13.65 L15.29,13.65 L14.57,11.148 C14.336,10.338 14.048,8.934 14.048,8.934 Z"
            id="A"
          />
          <rect
            id="Rectangle-4"
            x="5"
            y="11"
            width="2"
            height="2"
            rx="1"
          />
          <rect
            id="Rectangle-4-Copy"
            x="6"
            y="7"
            width="3"
            height="2"
            rx="1"
          />
          <rect
            id="Rectangle-4"
            x="4"
            y="15"
            width="2"
            height="2"
            rx="1"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'VaIconSlower',
}
</script>

<style lang="scss">
.va-icon-slower {
  width: 24px;
  height: 24px;
}
</style>
